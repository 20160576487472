import React, { useContext } from "react";
import { FormContext, FieldDataContext, InputContext } from "../../../contexts";
import { HorizontalSubformBody } from ".";
import { VerticalSubformBody } from ".";

import { Form, Button, ButtonGroup } from "react-bootstrap";

const Subform = ({ displayItem }) => {
  const subformType = displayItem.subformType;

  // Grab required values from context
  const {
    subformsValues,
    addNewRowTo,
    updateFieldValueForSubform,
    handleSubformDelete,
    reorderSubformRows,
    readonly,
    externalReferences,
    formValues,
  } = useContext(FormContext);

  const subform = subformsValues.find((subform) => {
    return subform.identifier === displayItem.subformIdentifier;
  });

  let anyDependenciesMet = false;

  if (subform.subformData.length == 0) {
    // if a subform has no rows, we check the dependencies of its template data
    // (in other words: if we were to add a row, would its dependencies be met?)
    for (const key in subform.templateData.fields) {
      const field = subform.templateData.fields[key];

      if (field.dependency_met) {
        anyDependenciesMet = true;
      }
    }
  } else {
    // Otherwise, we check all the fields inside the subform
    // We show the subform if any field meets its dependency
    anyDependenciesMet =
      subform.subformData.length == 0 ||
      subform.subformData.some((row) => {
        for (const key in row.fields) {
          const field = row.fields[key];

          if (field.dependency_met) {
            return true;
          }
        }
      });
  }

  if (!anyDependenciesMet) {
    return null;
  }

  const buildInputNameForRow = (fieldIdentifier, index) => {
    return `form[${subform.pluralizedIdentifier}_attributes][${index}][${fieldIdentifier}]`;
  };

  // Build the delete/restore button for a given row
  const buildDeleteButton = (row, index) => {
    if (row.marked_for_delete) {
      return (
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => {
            handleSubformDelete(subform.identifier, index, false);
          }}
        >
          <i
            className="fas fa-undo-alt me-1"
            title={`Restore subform ${
              subformType == "horizontal" ? "row" : "entry"
            }`}
          ></i>
          Undo
        </Button>
      );
    } else {
      return (
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => {
            handleSubformDelete(subform.identifier, index, true);
          }}
        >
          <i
            className="fa fa-trash me-1"
            title={`Remove subform ${
              subformType == "horizontal" ? "row" : "entry"
            }`}
          />
          Delete
        </Button>
      );
    }
  };

  const buildNewButton = () => {
    if (readonly) {
      return null;
    } else {
      return (
        <Button
          onClick={() => {
            addNewRowTo(displayItem.subformIdentifier);
          }}
          title={`Add ${
            subformType == "horizontal" ? "a row" : "an entry"
          } to ${displayItem.subformName}`}
        >
          <i className="fas fa-plus"></i>{" "}
          {`Add ${subformType == "horizontal" ? "a row" : "an entry"}`}
        </Button>
      );
    }
  };

  const buildReorderButtons = (index) => {
    return (
      <ButtonGroup className="ms-2">
        <Button
          size="sm"
          variant="outline-secondary"
          className="move-up"
          title={`Move subform ${
            subformType == "horizontal" ? "row" : "entry"
          } up`}
          disabled={index == 0}
          onClick={() => {
            reorderSubformRows(subform.identifier, index, -1);
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          className="move-down"
          title={`Move subform ${
            subformType == "horizontal" ? "row" : "entry"
          } down`}
          disabled={index == subform.subformData.length - 1}
          onClick={() => {
            reorderSubformRows(subform.identifier, index, 1);
          }}
        >
          <i className="fas fa-arrow-down"></i>
        </Button>
      </ButtonGroup>
    );
  };

  const buildHiddenFields = (row, index, row_num) => {
    return (
      <>
        <Form.Control
          hidden={true}
          value={row.id || ""}
          name={buildInputNameForRow("id", index)}
          readOnly={true}
        />
        <Form.Control
          hidden={true}
          // Only increment the row num if the current row is not marked for deletion, otherwise set -1 as it will be deleted anyway
          value={row.marked_for_delete ? -1 : row_num}
          name={buildInputNameForRow("row_num", index)}
          readOnly={true}
        />
        {row.marked_for_delete && (
          <Form.Control
            hidden={true}
            value={true}
            name={buildInputNameForRow("_destroy", index)}
            readOnly={true}
          />
        )}
      </>
    );
  };

  const buildRowContext = (index, row, contents) => {
    return (
      <FieldDataContext.Provider
        key={index}
        value={{
          fields: subform.fields,
          formValues: row.fields,
          updateFormValue: (fieldIdentifier, value) => {
            updateFieldValueForSubform(
              subform.identifier,
              index,
              fieldIdentifier,
              value
            );
          },
          buildInputName: (fieldIdentifier) => {
            return buildInputNameForRow(fieldIdentifier, index);
          },
          buildUniqueIdentifer: (fieldIdentifier) => {
            return `field_${subform.pluralizedIdentifier}_${index}_${fieldIdentifier}`;
          },
        }}
      >
        {contents}
      </FieldDataContext.Provider>
    );
  };

  const subformBodyProps = {
    subform,
    buildInputNameForRow,
    buildDeleteButton,
    buildNewButton,
    buildReorderButtons,
    displayItem,
    readonly,
    updateFieldValueForSubform,
    buildHiddenFields,
    buildRowContext,
  };

  if (subformType == "horizontal") {
    return (
      <HorizontalSubformBody {...subformBodyProps}></HorizontalSubformBody>
    );
  } else {
    return <VerticalSubformBody {...subformBodyProps}></VerticalSubformBody>;
  }
};

export default Subform;
