import React from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import RevisionInfo from "../RevisionInfo";

const TextInput = ({
  fieldData,
  onChangeHandler,
  errors,
  field,
  displayItem,
  readonly,
  placeholder,
  labelSubtext,
  fieldPostText,
  inputName,
  fieldID,
  preventSubmitOnEnter = false,
}) => {
  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline =
    inputContext == "inline" ||
    inputContext == "table" ||
    inputContext == "subform";

  const fieldName = inputName;

  // Build our form label
  const formLabel = () => {
    if (displayItem.hideLabel) {
      return null;
    }

    let labelProps = {
      column: true,
      sm: displayInline ? "auto" : 4,
    };

    let upperLabel = <div>{displayItem.label}</div>;

    let lowerLabel = labelSubtext ? (
      <div className="fs-6 fst-italic text-muted">{labelSubtext}</div>
    ) : null;

    return (
      <Form.Label {...labelProps}>
        {upperLabel}
        {lowerLabel}
      </Form.Label>
    );
  };

  const smallInput =
    displayItem.widgetType == "textSmall" ||
    field.widgetType == "date" ||
    field.widgetType == "partialDate";

  const partialDateField = smallInput && field.type == "partialDate";

  // Build our form input
  const formControl = () => {
    let formControlClasses = [];

    let value;

    if (typeof fieldData.value === "number") {
      value = fieldData.value;
    } else {
      value = fieldData.value || (readonly ? "[left blank]" : "");
    }

    let formControlProps = {
      type: "text",
      value,
      name: fieldName,
      onChange: (e) => {
        onChangeHandler(e);
      },
      isInvalid: errors.length > 0,
      placeholder: readonly ? null : placeholder,
    };

    // Most barcode scanners work by typing in the code and then simulating an enter keypress
    // We don't want this to submit the form, so can disable the default enter behaviour if specified
    const preventFormSubmission = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };
    if (preventSubmitOnEnter) {
      formControlProps["onKeyDown"] = preventFormSubmission;
    }

    if (displayInline) {
      formControlProps["sm"] = "auto";
    } else {
      formControlProps["sm"] = 8;
    }

    if (displayItem.widgetType == "textArea") {
      formControlProps["as"] = "textarea";
      formControlProps["rows"] = 5;
      formControlProps["cols"] = 20;
      if (inputContext == "subform") {
        formControlProps["className"] = "w-auto";
      }
    }

    if (smallInput) {
      formControlClasses.push("small-field");
    }

    if (readonly) {
      formControlProps["plaintext"] = true;
      formControlProps["readOnly"] = true;
      if (!fieldData.value && !(typeof fieldData.value === "number")) {
        formControlClasses.push("text-muted");
      }

      // If this is a readonly text area display as a div with the display-linebreak class.
      if (displayItem.widgetType == "textArea") {
        return (
          <div
            className={`display-linebreak ${
              readonly && !fieldData.value ? "text-muted" : null
            }`}
          >
            {fieldData.value || "[left blank]"}
          </div>
        );
      }
    }

    if (formControlClasses.length > 0) {
      formControlProps["className"] = formControlClasses.join(" ");
    }

    return <Form.Control {...formControlProps}></Form.Control>;
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  const tooltipData = (placeholer) => {
    switch (placeholder) {
      case "{dd}/mm/yyyy":
        return "Month and year are required, day is optional (but should be entered if known)";
      case "{dd}/{mm}/yyyy":
        return "Year is required, day and month are optional (but should be entered if known)";
      case "mm/yyyy":
        return "Month and year are required";
      case "{mm}/yyyy":
        return "Year is required, month is optional (but should be entered if known)";
      default:
        return null;
    }
  };

  const shouldAlignCenter = !(displayItem.widgetType == "textArea");

  if (displayInline) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className={`mb-2 ${shouldAlignCenter ? "align-items-center" : null}`}
          id={fieldID}
        >
          {formLabel()}
          <Col sm={"auto"}>
            {formControl()}
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {errorFeedback}
          </Col>
          {fieldPostText ? (
            <Col sm={"auto"}>
              <div className="fs-6 fst-italic text-muted">{fieldPostText}</div>
            </Col>
          ) : null}
        </Form.Group>
      </Col>
    );
  } else {
    return (
      <Form.Group
        controlId={fieldName}
        as={Row}
        className={`mb-2 ${shouldAlignCenter ? "align-items-center" : null}`}
        id={fieldID}
      >
        {formLabel()}
        <Col
          data-tooltip-id={partialDateField ? "my-tooltip" : null}
          data-tooltip-content={
            partialDateField ? tooltipData(placeholder) : null
          }
          sm={smallInput ? "auto" : null}
        >
          <Tooltip id="my-tooltip" />
          {formControl()}
          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
        </Col>
        {fieldPostText ? (
          <Col sm={"auto"}>
            <div className="fs-6 fst-italic text-muted">{fieldPostText}</div>
          </Col>
        ) : null}
      </Form.Group>
    );
  }
};

export default TextInput;
