import React from "react";

// Display Items
import {
  ProspectField,
  FieldSet,
  ProspectTable,
  TableCell,
  RowLabel,
  InlineFields,
  RadioTable,
  HorizontalSubform,
  VerticalSubform,
  Subform,
} from "../components/data_entry/display_items";

export const buildDisplayItems = (displayItems) => {
  return displayItems.map((displayItem, index) => {
    return selectDisplayComponent(displayItem, index);
  });
};

export const selectDisplayComponent = (displayItem, index) => {
  switch (displayItem.type) {
    case "field": {
      return <ProspectField displayItem={displayItem} key={index} />;
    }
    case "fieldset": {
      return <FieldSet displayItem={displayItem} key={index}></FieldSet>;
    }
    case "inlineFields": {
      return <InlineFields displayItem={displayItem} key={index} />;
    }
    case "table": {
      return (
        <ProspectTable displayItem={displayItem} key={index}></ProspectTable>
      );
    }
    case "tableCell": {
      return <TableCell displayItem={displayItem} key={index}></TableCell>;
    }
    case "rowLabel": {
      return <RowLabel displayItem={displayItem} key={index}></RowLabel>;
    }
    case "radioTable": {
      return <RadioTable displayItem={displayItem} key={index} />;
    }
    case "subform": {
      return <Subform displayItem={displayItem} key={index} />;
      // if (displayItem.subformType == "horizontal") {
      //   return <HorizontalSubform displayItem={displayItem} key={index} />;
      // } else {
      //   return <VerticalSubform displayItem={displayItem} key={index} />;
      // }
    }
    default: {
      console.warn(
        `Unable to render layout item of type: "${displayItem.type}"`
      );
      return null;
    }
  }
};
