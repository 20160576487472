/**
 * Looks at the current value and determines if we consider it to be empty
 * @param {object} field_value
 * @returns boolean
 */
export const checkForEntered = (field_value) => {
  if (field_value.value == null) {
    return false;
  }

  if (typeof field_value.value == "boolean") {
    return field_value.value;
  }

  if (Array.isArray(field_value.value)) {
    return field_value.value.length > 0;
  }

  if (typeof field_value.value == "number") {
    return true;
  }

  if (field_value.value.trim() != "") {
    return true;
  }

  return false;
};

export const processFormValues = (
  fields,
  formData,
  externalReferences,
  subformsValues
) => {
  // Organise our form values into the required structure
  let processedFormValues = {};
  fields.forEach((field) => {
    let field_value = formData[field.identifier];

    if (!field_value) {
      return;
    }

    // If the field type is flag, sort the values - this lets us compare current/initial values without worrying about ordering
    if (field.type == "flag" && field_value.initial_value != null) {
      field_value.initial_value.sort();
    }

    // does this field have a value entered?
    field_value.entered = checkForEntered(field_value);

    let processedValue = {
      ...field_value,
      type: field.type,
      // TODO: Before Live: Remove this. This allows an error message to be displayed on all fields for debugging purposes.
      // errors: ["This is an error test"],
    };

    processedFormValues[field.identifier] = processedValue;
  });

  return processedFormValues;
};
