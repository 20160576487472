import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
// To use this component the links prop needs to be in this format
// {
//   "Section Name": [{
//     "name": value,
//     "url": value
//   }]
// }
function DropDownLinks({ button_text, links }) {
  const DropdownSections = () => {
    let processedDropdownSections = [];
    const linksLength = Object.keys(links).length;

    Object.keys(links).forEach((key, index) => {
      const items = links[key];
      processedDropdownSections.push(
        <Dropdown.Header key={key + index}>{key}</Dropdown.Header>
      );
      processedDropdownSections.push(DropdownItems(items));
      if (index < linksLength - 1)
        processedDropdownSections.push(
          <Dropdown.Divider key={"divider" + key + index} />
        );
    });
    return processedDropdownSections;
  };
  const DropdownItems = (links) => {
    return links.map((link, index) => {
      return (
        <Dropdown.Item
          href={link["url"]}
          key={index}
          className={link["active"] ? "active" : null}
        >
          {link["name"]}
        </Dropdown.Item>
      );
    });
  };

  return (
    <Dropdown drop="down-centered">
      <Dropdown.Toggle
        className="px-5"
        variant="outline-secondary"
        id="dropdown-basic"
      >
        {button_text}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-60vh-max">
        {DropdownSections()}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropDownLinks;
