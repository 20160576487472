import React from "react";
import { useContext } from "react";
import { FormContext } from "../../contexts";

const RevisionInfo = ({ fieldData }) => {
  const { auditPage } = useContext(FormContext);

  if (!auditPage) {
    return null;
  }

  const PreviousValue = () => {
    if (!fieldData.previous_value) {
      return null;
    }

    let displayValue;

    if (Array.isArray(fieldData.previous_value)) {
      displayValue = fieldData.previous_value.join(", ");
    } else {
      displayValue = fieldData.previous_value.toString();
    }

    return <div>Previous Value: {displayValue}</div>;
  };

  const CurrentValue = () => {
    if (!fieldData.current_value) {
      return null;
    }

    let displayValue;

    if (Array.isArray(fieldData.current_value)) {
      displayValue = fieldData.current_value.join(", ");
    } else {
      displayValue = fieldData.current_value.toString();
    }

    return <div>Current Value: {displayValue}</div>;
  };

  return (
    <div className="text-success">
      {PreviousValue()}
      {CurrentValue()}
    </div>
  );
};

export default RevisionInfo;
