import React from "react";
import { useContext } from "react";

import { buildDisplayItems } from "../../../services/display_item_helper";
import { anyChildIsRendered } from "../../../services/dependencies/dependency_helper";
import { FieldDataContext, FormContext } from "../../../contexts";

const FieldSet = ({ displayItem }) => {
  // Get required values from our context
  const { formValues } = useContext(FieldDataContext);
  const { fields, readonly } = useContext(FormContext);

  if (!anyChildIsRendered(displayItem.children, formValues, fields, readonly)) {
    return null;
  }

  const contents = buildDisplayItems(displayItem.children);

  const legend = displayItem.legend ? (
    <legend className="fs-5 px-1 fw-bold">{displayItem.legend}</legend>
  ) : null;

  if (contents.length == 0) {
    console.warn("A fieldset should contain children, not rendering");
    return null;
  } else {
    return (
      <fieldset className="prospect-fieldset rounded border border-1 pt-3 pb-2 mt-4">
        {legend}
        {contents}
      </fieldset>
    );
  }
};

export default FieldSet;
